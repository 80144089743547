import React from "react";

const Home: React.FC = () => {
    return (
        <div className="full-page text-white">
            <div className="full-page column-centered justify-center" >
                <div className="mx-auto">
                    <p className="text-center text-shadow-lg shadow-dark text-main my-3">max pinheiro</p>
                    <p className="text-center text-shadow-lg shadow-dark text-header">web developer</p>
                </div>
            </div>
        </div>
    );
}

export default Home;