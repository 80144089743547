import React, { useState } from "react";
import { ProjectDescription, ProjectTag } from "../../media/projects";
import { getCardShape, getTextTheme } from "../../store/Theme.store";
import { useSelector } from "react-redux";

interface ProjectListProps {
    projects: ProjectDescription[];
    cardClasses: string[];
    selectProject: (idx: number) => void;
    filterType: ProjectTag | null;
    setFilterType: (type: ProjectTag | null) => void;
}

type CardShape = "card-bubble" | "card-circle";

const ProjectList: React.FC<ProjectListProps> = ({ projects, cardClasses, selectProject, filterType, setFilterType }) => {
    const textClass = useSelector(getTextTheme);
    const [ cardShape, setCardShape ] = useState<CardShape>('card-bubble');
    const filteredProjects = filterType === null ? projects : projects.filter(p => p.tags && p.tags.includes(filterType));
    
    return (
        <>
            <div className="row-center mt-3">
                <i className={`fas fa-square fa-2x ${cardShape === 'card-bubble' ? 'text-white' : 'greyed-out'} mx-2 pointer`} onClick={() => setCardShape('card-bubble')}></i>
                <i className={`fas fa-circle fa-2x ${cardShape === 'card-circle' ? 'text-white' : 'greyed-out'} mx-2 pointer`} onClick={() => setCardShape('card-circle')}></i>
            </div>
            <div id="project-list" className="row wrap text-center text-heading">
                {
                    filteredProjects.map((project, idx) => 
                    <div className="col-6 col-lg-4" key={idx}>
                        <div className={`pointer ${cardShape} ${cardClasses[idx]} m-4 row-center align-center `} 
                            id={`card${idx+1}`} 
                            onClick={() => selectProject(projects.indexOf(project))}>
                            <p className="h3 mt-2 text-medium text-22 md-text-35 disabled" style={{userSelect: 'none'}}>{project.title}</p>
                        </div>
                    </div>)
                }
            </div>
        </>
    );
}

export default ProjectList;
