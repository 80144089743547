import React from "react";
import { useSelector } from "react-redux";
import { getBodyTextTheme } from "../store/Theme.store";

const Random: React.FC = () => {
    const bodyClass = useSelector(getBodyTextTheme);

    return (
        <div className={`column-centered align-center justify-end full-page  ${bodyClass}`} id="random">
            <p className="my-4">why are you still scrolling?</p>
            <p className="pointer py-5" onClick={() => window.scrollTo(0, 0)}>back to top</p>
        </div>
    );
};

export default Random;

