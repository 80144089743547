export type PortfolioPiece = {
    name: string;
    url: string;
    about?: string;
    fullLink?: string;
}

export const sketches: PortfolioPiece[] = [
    {
        name: "Zap!",
        url: "https://drive.google.com/uc?export=view&id=1-ytRgGRWHT_ziuui9EEqlzbsc3sodE-A"
    },
    {
        name: "At Sea",
        url: "https://drive.google.com/uc?export=view&id=1d5wKUNkCkiYnQXpENi_yrUDgbtyzExIN"
    },
    {
        name: "Tidal Sunset",
        url: "https://drive.google.com/uc?export=view&id=1kUndJGWpbmPxsnxUHlg4CZXO1Ccg1zwZ"
    },
    {
        name: "Pricked",
        url: "https://drive.google.com/uc?export=view&id=1MtgtHXnCMO5gWOfUrmd0I-JLDbDJvyfh"
    },
    {
        name: "Primary",
        url: "https://drive.google.com/uc?export=view&id=1vH1Wcm2OuQT_K5ThiBsT0O1L1ocHQBzI"
    }
];

export const paintings: PortfolioPiece[] = [
    {
        name: "Shapes I",
        url: "https://drive.google.com/uc?export=view&id=1QbfRbmeRBNafp6mIB-fjNJ2vePfiTH4L"
    },
    {
        name: "Succulent",
        url: "https://drive.google.com/uc?export=view&id=1EqVHcBodKsqrQKy8gnOM2HQLQL849h0o"
    },
    {
        name: "Shapes II",
        url: "https://drive.google.com/uc?export=view&id=14gsI_tWcJDhoIBPFuR21JnRpQ8OYS8-9"
    },
    /*{
        name: "The Bubbler",
        url: "https://drive.google.com/uc?export=view&id=1SqIa652djxLdLuCI2hkLuFNv5r1WUGU9"
    },*/
    {
        name: "Sugar Pills",
        url: "https://drive.google.com/uc?export=view&id=1pFBwPw9RZ1wyYSkca1e8LXPlBH8mVl5L"
    },
    {
        name: "Family Portraits: Dr. Dandelion",
        url: "https://drive.google.com/uc?export=view&id=1zjZqZVTJwJPBvVZtlbV2ASKQx-ZSLOj5"
    },
    {
        name: "Family Portraits: Mdm. Mushroom",
        url: "https://drive.google.com/uc?export=view&id=16U9OtHeAF1RVQ-gW4Jqp9XqlFhxZmtSr"
    },
    {
        name: "Kaleidoscopic Woman",
        url: "https://drive.google.com/uc?export=view&id=1n_vEWfQtbwgRwyf9T_DDNbEp360-Dv6Q"
    },
    {
        name: "Reaching for Adam",
        url: "https://drive.google.com/uc?export=view&id=1sm2dVBCXTdstM8Ko7gFrqQoX0_zUAgrh"
    }
]

export const graphics: PortfolioPiece[] = [
    {
        name: "Triangles",
        url: "https://drive.google.com/uc?export=view&id=1u692pq0LlFKsSY82V8Ei8vCFG2JScz60"
    },
    {
        name: "The Party",
        url: "https://drive.google.com/uc?export=view&id=1Km5YbXboNz5zi3ZKILNdZ_ST4dKCdi1B"
    },
    {
        name: "Shapes of Kazumasa Nagai, combined",
        url: "https://drive.google.com/uc?export=view&id=15l83HZRwPYIEEy5h-Fc8St-piMYPz1qv",
        about: "This was created for my art course, Design Process Context & Systems. The project was to research a famous graphic artist (in my case, Kazumasa Nagai) and create promotional designs in the style of the chosen artist."
    },
    {
        name: "Kazumasa Triangles, gif",
        url: "https://drive.google.com/uc?export=view&id=1maSUQVaJVrizWoVK5zcIt3VWdjJAMQIS"
    },
    {
        name: "Kasumasa Stairs, gif",
        url: "https://drive.google.com/uc?export=view&id=166qlDRXRR4G32pqPZlfYaBNuEiiofMct"
    },
    {
        name: "Kazumasa Circles, gif",
        url: "https://drive.google.com/uc?export=view&id=1udp1XUAxNBK-vdimsD5PoHmCfn-mZFKB"
    }
];

export const miscellaneous: PortfolioPiece[] = [
    /*{
        name: "Chain Grip: Product",
        url: "https://drive.google.com/uc?export=view&id=1o6JPr0LqkBLc_N6JGPSqnPaFVAjYpyFV"
    },*/
    {
        name: "Chain Grip: Product (Resting Position)",
        about: "The Chain Grip is a product I mocked for my art course, Design Process Context & Systems. Here is the product's resting position, in which it functions as a pant chain.",
        url: "https://drive.google.com/uc?export=view&id=166N7K9Xn0V_ENXkgk18I5BwvnWWUsZH4"
    },
    {
        name: "Chain Grip: Product (Ring Position)",
        about: "The Chain Grip is a product I mocked for my art course, Design Process Context & Systems. Here the chain is released and worn on the finger.",
        url: "https://drive.google.com/uc?export=view&id=1NVC91MOo1bU6W7cRmEPc1YOjrMgetefs"
    },
    {
        name: "Chain Grip: Product (Measure Position)",
        about: "The Chain Grip is a product I mocked for my art course, Design Process Context & Systems. Here we see the true purpose of the product; the product is custom-made for each owner, and the silver beads are spaced to line up with the fingertip, wrist, and elbow of the owner.",
        url: "https://drive.google.com/uc?export=view&id=1jx7pNyby2mcGDMe3nbgb3e-3j1-whzBg"
    },
    {
        name: "Chain Grip: Logo",
        url: "https://drive.google.com/uc?export=view&id=1vQ3_d9NOiwhiPIMUaOk2IHOsyovTn5FF"
    },
    {
        name: "Love in the Hub: Example Page (Esplanade)",
        url: "https://drive.google.com/uc?export=view&id=1LPdG9nGZoXedyL8TTWbfxKMglmTFY8Uz",
        about: "Love in the Hub is a booklet I created for my art course, Design Process Context & Systems. It is an artistic pamphlet that provides suggestions for date spots around Boston.",
        fullLink: "https://maxpinheiro.com/files/love_in_the_hub_spreads.pdf"
    }
];