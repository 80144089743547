import React from "react";
import { Link } from "react-router-dom";
import profile from "../../media/profile.png";
import { useSelector } from "react-redux";
import { getAboutBackground, getBodyTextTheme, getHeadingTextTheme, getTextTheme } from "../../store/Theme.store";
import Skills from "./Skills";
import Experience from "./Experience";

const aboutParagraphs: (JSX.Element | string)[] = [
    "Hi, I'm Max! I'm a software developer currently studying at Northeastern University, \
    pursuing a BS in Computer Science with a concentration in Artificial Intelligence and a \
    minor in Interaction Design. I consider myself flexible to all fields within software development, but \
    I take particular interest in web development and artificial intelligence.   \
    Above all else, I wish to use my skills to make a positive impact, whether it be for myself, a community, or the world.",
    <p className="m-0">In addition to software development, I am also pursuing graphic design. My combined passion for
    graphic design and computer science drive me to build creative and meaningful user interfaces. 
    Check out a small collection of my artwork <Link to="/portfolio" style={{textDecoration: "underline"}}>here</Link>.</p>
]

const About: React.FC = () => {
    const backgroundClass = useSelector(getAboutBackground);
    const textClass = useSelector(getTextTheme);
    const headingClass = useSelector(getHeadingTextTheme);
    const bodyClass = useSelector(getBodyTextTheme);

    return (
        //<div className={`full-page ${backgroundClass} ${textClass}`}>
        <div className={`full-page ${bodyClass}`} id="about">
            <div className="column-centered pt-6">
                <div className={`row-center align-center border-bottom border-${textClass.split('text-')[1]} px-3`}>
                    <p className={`text-center text-header ${headingClass} my-2`}>about me</p>
                </div>
                <span className="row wrap align-center mx-auto lg-w-85">
                    <div className="col-12 col-lg-3 column-centered">
                        <img src={profile} alt="profile" className="img-fluid rounded-circle border border-dark hover-border-white shadow-lg mt-4 lg-m-0" 
                            style={{width: "250px", height: "250px"}}
                        />
                    </div>
                    <div className="column-centered col-12 col-lg-9">
                        {
                            aboutParagraphs.map((text, idx) => (
                                <p key={`abop-${idx}`} className="text-about text-justify line-height-150 mx-5 px-3 py-2 bg-secondary-10">
                                    {text}
                                </p>
                            ))
                        }
                    </div>
                </span>
                <Skills />
            </div>
        </div>
    );
}

export default About;
