import React from "react";

export const DesktopBackground: React.FC<{primary: string, secondary: string, tertiary: string, quaternary: string}> = ({primary, secondary, tertiary, quaternary}) => (
    <svg className="desktop-only" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366 768" preserveAspectRatio="xMinYMid slice" style={{position:'fixed', top:0, left:0, height:'100%', width:'100%', zIndex: -1}}>
        <g>
            <polygon className={tertiary} fill={tertiary} points="532 809 540.74 -40.56 1286 -25 1295 802 532 809"/>
            <polygon className={primary} fill={primary} points="-31 819 -31 -31 659.39 -28.73 306 363 357 607 -31 819"/>
            <path className={quaternary} fill={quaternary} d="M1256,199c-24.45,24.68-72.21-23.24-143-11-106.25,18.37-156.8,153.66-164,173-11.4,30.61-33.49,89.88-7,120,25.7,29.22,77.38,8.55,93,36,17,29.93-32.78,75-19,93,18.76,24.55,117.37-59.09,175-28,65.71,35.45,16.3,186.54,85,226,32.21,18.5,76.31,4.38,113-14L1452-9c-6.4-7.66-48.31-56.39-100-50-57,7-90.4,76.76-99,124C1240.23,135.13,1278.28,176.52,1256,199Z"/>
            <path className={secondary} fill={secondary} d="M204,438c-6.56-18.09-33.74-93.05-7-159,17-42,65.59-70.58,162-126C475.87,85.82,513.25,82.66,561,26c35.63-42.28,28.93-56.56,58-76,66.17-44.25,172.58-18.21,240,32,52,38.76,123.77,125.34,99,187-41.78,104-325.87,56.44-339,124-10.33,53.13,162.78,95.71,166,196,2.47,76.74-96.78,117.74-78,169,15.26,41.65,85.67,27.87,159,87,43.81,35.33,15,63.33,27,92-328.27-4.56-604.73,6.56-933,2-36-39.06-45.32-58.34-40-66,10.76-15.5,71.23,31.4,114,9,47-24.61,13.48-101.87,64-154,57.12-59,159.27-18.37,173-60C281.36,536.6,232.7,517.17,204,438Z"/>
        </g>
    </svg>
);

export const MobileBackground: React.FC<{primary: string, secondary: string, tertiary: string, quaternary: string}> = ({primary, secondary, tertiary, quaternary}) => (
    <svg className="mobile-only" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300 670" preserveAspectRatio="none" style={{position:'fixed', top:0, left:0, zIndex: -1}}>
        <polygon id="purple" className={quaternary} fill={quaternary} points="400.32 299.2 400.32 670 0.32 670 0.32 399.08 400.32 299.2"/>
        <path id="blue" className={tertiary} fill={tertiary} d="M400.32,0H345.15L0,224.77V499.24s21.89,5.87,31.76,20.16c6.3,9.12,3.48,21.36,2.44,32.4-1.12,12,.46,24.76,7.56,34.47s20.74,15.21,31.73,10.3c7.9-3.53,12.87-11.33,17.37-18.72l16.56-27.17c9.64-15.81,21.63-33.23,40-35.77a15.68,15.68,0,0,1,9.22,1.06c6.71,3.42,7.59,12.37,9.11,19.74,3.05,14.9,12.67,28.65,26.36,35.3s31.24,5.4,43-4.27c22.94-18.9,16.34-58.22,37-79.63,7.81-8.11,14.71-14.92,44.46-14.92,41.61,0,71-49.62,46-84.4-17-23.61,35.48-82.14,37.82-86Z"/>
        <path id="green" className={secondary} fill={secondary} d="M377,183.41c-4.11-5.6-23-18.13-29-23.17-6.86-5.76-8.11-15-9.7-24.67-1.81-10.95,12.71-18.36,27.18-35.1,8.95-10.36,13.59-40.73,10.21-53.81C372.7,35.07,350,0,350,0H261.41L0,102.08V255.93c4.93-8.74,14.67-7.45,21.9-.49s9.08,17.82,8.89,27.85c-.41,22.11-8.61,43.21-14.07,64.64s-7.93,45.22,2.38,64.79C25.16,424.23,39,434,50.68,428.28c8.75-4.32,12.3-15.52,20.9-20.13,9.54-5.1,21.42-.18,29.91,6.52s15.85,15.36,26,19.08c12,4.4,25.65.85,36.84-5.36,32.83-18.21,50-60.55,39.24-96.5-2.74-9.12-7.11-18.4-5.25-27.75,1.47-7.35,6.58-13.36,11.51-19,10.19-11.7,26.12-24.46,39.31-16.31,9.3,5.74,10.6,18.51,16.1,27.95,9.67,16.61,34,21.24,50.62,11.58s24.8-30.61,22.12-49.65c-.7-4.94-2-10.11-.24-14.77,1.46-3.84,4.79-6.64,8.21-8.89,15.91-10.48,17.25-3.83,30.68-17.34C385.66,208.62,382.69,191.16,377,183.41Z"/>
        <path id="grey" className={primary} fill={primary} d="M297.12,0H.32L0,159.76s.63,13.45.63,17c0,20.21,25.27-24.42,35.5-34a13.32,13.32,0,0,1,6-3.49c5.1-1.13,10.33,2.36,12.92,6.9s16.16,56.92,16.7,62.13c4.41,42.37-2,14.51,6.65,56.22C80,272,81.87,279.87,87.3,285.24c7.82,7.72,21.14,7.5,30.57,1.85s14.37-33.72,14.82-44.7c1.91-46.9-4.09-38.82-11.86-78.57-1.91-9.77-12-27.94-9.46-37.57s8.17-11.77,18.09-10.86c8.42.76,14.78,8,18.73,15.48s6.53,15.81,12,22.23A30.6,30.6,0,0,0,212.47,143c2.7-8,.66-27.18-5.34-33.18-10.27-10.27-9.54-19.73-.11-25.23,9.09-5.3,42.87,21.68,54.33-3.5C270.12,61.82,248,62.21,249.94,36,251.13,19.78,297.12,0,297.12,0Z"/>
    </svg>
);
