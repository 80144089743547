import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {descriptions, ProjectTag} from "../../media/projects";
import { getBodyTextTheme, getCardClasses, getCardShape, getHeadingTextTheme, getProjectsBackground, getTextTheme, setCardShape } from "../../store/Theme.store";
import ProjectDetails from "./ProjectDetails";
import ProjectList from "./ProjectList";

const filterTypes: (ProjectTag | null)[] = [null, 'tools', 'games', 'fun', 'music', 'school', 'art'];

const Projects: React.FC = () => {
    const dispatch = useDispatch();
    const [selectedProject, setSelectedProject] = useState(-1);
    const backgroundClass = useSelector(getProjectsBackground);
    const textClass = useSelector(getTextTheme);
    const headingClass = useSelector(getHeadingTextTheme);
    const bodyClass = useSelector(getBodyTextTheme);
    const cardClasses = useSelector(getCardClasses);
    const [cardColors, setCardColors] = useState(descriptions.map(() => cardClasses[Math.floor(Math.random() * cardClasses.length)]));
    const [filterType, setFilterType] = useState<ProjectTag | null>(null);
    
    const setColors = useCallback(() => {
        const classes = descriptions.map(() => cardClasses[Math.floor(Math.random() * cardClasses.length)]);
        setCardColors(classes);
    }, [ cardClasses ]);

    useEffect(() => {
        setColors();
    }, [ cardClasses, setColors ]);

    return (
        //<div className={`full-page ${backgroundClass} ${textClass}`} id="projects">
        <div className={`full-page ${bodyClass}`} id="projects">
            <div className="column-centered pt-6">
                <div className={`row-center align-center border-bottom border-${textClass.split('text-')[1]} px-3`}>
                    <p className={`text-center text-header ${headingClass} my-2`}>projects</p>
                    <h1 className="bi bi-arrow-repeat shadow-dark font-bold mx-3 pointer row align-center" onClick={() => setColors()} />
                </div>
                <div className="row w-100 justify-around wrap mt-3 mx-bw-3">
                    { 
                        filterTypes.map((type, idx) => (
                            <div key={type} onClick={() => setFilterType(type)} className={`col-2 col-lg-1 pointer `}>
                                <p className={`text-about text-center pb-2 px-2 my-2 mx-auto w-max-content border-bottom ${filterType === type ? `border-${textClass.split('text-')[1]}` : 'border-transparent'}`}>{type || 'all'}</p>
                            </div>
                        ))
                    }
                </div>
                <div className="lg-mx-5 lg-px-5 mt-0 pt-0 mb-5">
                    {
                        selectedProject === -1 ?
                        <ProjectList 
                            projects={descriptions} 
                            cardClasses={cardColors}
                            selectProject={setSelectedProject} 
                            filterType={filterType}
                            setFilterType={setFilterType}
                        /> :
                        <ProjectDetails
                            project={descriptions[selectedProject]}
                            cardClass={cardColors[selectedProject]}
                            textClass={textClass}
                            deselect={() => setSelectedProject(-1)}
                        />
                        
                    }
                </div>
            </div>
        </div>
    );
}

export default Projects;
