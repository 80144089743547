import React from "react";
import { useSelector } from "react-redux";
import { getAboutBackground, getTextTheme } from "../store/Theme.store";

const NotFoundPage: React.FC = () => {
    const backgroundClass = useSelector(getAboutBackground);
    const textClass = useSelector(getTextTheme);

    return (
        <div className={`full-page ${backgroundClass} ${textClass}`}>
            <div className="column-centered">
                <p>Page Not Found!</p>
            </div>
        </div>
    );
}

export default NotFoundPage;
