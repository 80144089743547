import React, {useState} from "react";
import { useSelector } from "react-redux";
//import { getAllSketches, getAllPaintings, getAllGraphics, getAllPieces, getRandomPiece, Piece } from "../art-service";
import BounceLoader from "react-spinners/BounceLoader";
import { getBodyTextTheme, getHeadingTextTheme, getPortfolioBackground, getTextTheme } from "../store/Theme.store";
import { sketches, paintings, graphics, miscellaneous, PortfolioPiece } from "../media/portfolio";
import { Link } from "react-router-dom";

export type PieceType = 'sketches' | 'paintings' | 'graphic art' | 'all pieces' | 'random piece' | 'miscellaneous';

interface PortfolioProps {
    homePage?: boolean
}

const Portfolio: React.FC<PortfolioProps> = ({ homePage }) => {
    const types: PieceType[] = homePage ? ['random piece'] : ['random piece', 'all pieces', 'paintings', 'sketches', 'graphic art', 'miscellaneous'];
    const [selectedType, setSelectedType] = useState<PieceType | null>(null);
    const [pieces, setPieces] = useState<PortfolioPiece[] | null>(null);
    const textClass = useSelector(getTextTheme);
    const headingClass = useSelector(getHeadingTextTheme);
    const bodyClass = useSelector(getBodyTextTheme);
    const [loadedCt, setLoadedCt] = useState(0);

    function selectWorks(type: PieceType, shuffle: boolean) {
        setSelectedType(type);
        setPieces(null);
        //setLoadedIdxs([]);
        setLoadedCt(0);

        if (type === 'sketches') {
            setPieces(shuffle ? sketches.sort((a, b) => 0.5 - Math.random()) : sketches);
            //getAllSketches().then(pieces => setPieces(pieces))
        } else if (type === 'paintings') {
            setPieces(shuffle ? paintings.sort((a, b) => 0.5 - Math.random()) : paintings);
            //getAllPaintings().then(pieces => setPieces(pieces))
        } else if (type === 'graphic art') {
            setPieces(shuffle ? graphics.sort((a, b) => 0.5 - Math.random()) : graphics);
            //getAllGraphics().then(pieces => setPieces(pieces))
        } else if (type === 'miscellaneous')  {
            setPieces(shuffle ? miscellaneous.sort((a, b) => 0.5 - Math.random()) : miscellaneous);
        } else if (type === 'all pieces') {
            const allPieces: PortfolioPiece[] = [...sketches, ...paintings, ...graphics];
            setPieces(shuffle ? allPieces.sort((a, b) => 0.5 - Math.random()) : allPieces);
            //getAllPieces().then(pieces => setPieces(shuffleArray(pieces)))
        } else if (type === 'random piece') {
            const allPieces: PortfolioPiece[] = [...sketches, ...paintings, ...graphics];
            const idx = Math.floor(Math.random() * allPieces.length);
            setPieces([allPieces[idx]]);
            //getRandomPiece().then(pieces => setPieces(pieces))
        }
    }

    return (
        //<div className={`full-page ${backgroundClass} ${textClass}`} id="portfolio">
        <div className={`full-page ${bodyClass}`} id="portfolio">
            <div className="column-centered pt-6">
                <div className={`row-center align-center border-bottom border-${textClass.split('text-')[1]} px-3`}>
                    <p className={`text-center text-header ${headingClass} my-2`}>portfolio</p>
                </div>
                {
                    selectedType === null && 
                    <div className="responsive-falling-row my-5">
                        {
                            types.map((type, idx) => 
                            <div className={`column border border-${textClass.split('text-')[1]} rounded-lg py-3 px-3 my-1 mx-2 pointer hover:bg-${textClass.split('text-')[1]} hover:bg-opacity-50`} key={idx} onClick={() => selectWorks(type, false)} ><p className="text-center mx-auto  my-auto text-title">{type}</p></div>)
                        }
                    </div>
                }
                {
                    selectedType !== null && pieces !== null && 
                    <div>
                        {
                            <span className="row-center align-center my-3">
                                <div className="pointer" onClick={() => {setSelectedType(null); setPieces(null); setLoadedCt(0)}}><i className="fas fa-arrow-left fa-2x"></i></div>
                                <p className="text-center text-medium my-auto mx-3">{selectedType}</p>
                            </span>
                        }
                        <div className="column-centered">
                            {
                                loadedCt < pieces.length &&
                                <div className="column-centered my-4">
                                    <BounceLoader color="#FFFFFF" size={60} loading={true} />
                                    <p className="text-center text-body">fetching artwork...</p>
                                </div>
                            }
                            {
                                selectedType === 'random piece' && loadedCt === pieces.length &&
                                <p className={`text-heading pointer border border-${textClass.split('text-')[1]} rounded-lg px-2 m-0 mb-3 `} onClick={() => selectWorks('random piece', false)}>new random piece</p>
                            }
                            {
                                selectedType === 'all pieces' && loadedCt === pieces.length &&
                                <p className={`text-heading pointer border border-${textClass.split('text-')[1]} rounded-lg px-2 m-0 mb-3 `} onClick={() => selectWorks('all pieces', true)}>shuffle pieces</p>
                            }
                            {
                                pieces.map(({name, url, about, fullLink}, idx) => (
                                    <div key={idx} className="mb-5 column-centered">
                                        {<img src={url} alt={name} style={{maxHeight: "70vh", maxWidth: "75vw"}} onLoad={() => setLoadedCt(prev => prev + 1)} />}
                                        {
                                            loadedCt === pieces.length && 
                                            <>
                                                <p className="text-center text-title font-italic mt-1 mb-0 mx-3">{name}</p>
                                                <div className="row-center align-center">
                                                    {about && <p className="pointer underline my-0 mr-2" onClick={() => alert(about)}>About</p>}
                                                    {fullLink && <a href={fullLink} target="_blank" rel="noopener noreferrer">Full Piece</a>}
                                                </div>
                                            </>
                                        }
                                    </div>
                                ))
                            }

                            {
                                pieces.length > 3 &&
                                <p className="text-about pointer underline my-3" onClick={() => document.getElementById('portfolio')?.scrollIntoView()}>back to top</p>
                            }
                            
                        </div>
                    </div>   
                }
                { 
                    homePage ? 
                    <Link to="/portfolio">see full portfolio</Link> :
                    <Link to="/#/%23portfolio">back to home</Link>
                }
            </div>
        </div>
    );
}

export default Portfolio;