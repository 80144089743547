import React from 'react';
import { useSelector } from "react-redux";
import {HashRouter as Router, Routes, Route, Link} from 'react-router-dom';
import About from '../pages/About/About';
import Contact from '../pages/Contact';
import Home from '../pages/Home/Home';
import Navbar from '../pages/Navbar/Navbar';
import Portfolio from '../pages/Portfolio';
import Projects from '../pages/Projects/Projects';
import NotFoundPage from './NotFoundPage';
import Random from './Random';
import { DesktopBackground, MobileBackground} from "../pages/Home/Background";
import { getBaseColor, getColorPalette, getTextTheme } from "../store/Theme.store";

const SinglePageApp = () => {
    return (
        <>
            <Home />
            <About />
            <Projects />
            <Portfolio homePage />
            <Contact />
            <Random />
        </>
    );
}

const App: React.FC = () => {
const palette = useSelector(getColorPalette);

  return (
    <div className='full-page'>
        <DesktopBackground primary={palette.primary} secondary={palette.secondary} tertiary={palette.tertiary} quaternary={palette.quaternary} />
        <MobileBackground primary={palette.primary} secondary={palette.secondary} tertiary={palette.tertiary} quaternary={palette.quaternary} />
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<SinglePageApp />}/>
                <Route path="/about" element={<About />}/>
                <Route path="/projects" element={<Projects />}/>
                <Route path="/portfolio" element={<Portfolio />}/>
                <Route path="/contact" element={<Contact />}/>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;
